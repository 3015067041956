.dashboard_container {
  /* max-width: 1060px; */
  margin-left: 240px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(467px, 1fr));
}

.graph_title {
  margin: 0;
  padding-top: 10px;
  text-align: left;
  font-size: 18px;
}

.graph_container {
  /* max-width: 550px; */
  /* max-height: 550px; */
}

.graph_container > .MuiPaper-root,
.graph_container_circle > .MuiPaper-root {
  padding: 0 15px;
  height: 100%;
}

.graph_container_circle > .MuiPaper-root > div:last-child {
  display: initial;
  max-width: 300px;
  width: 100%;
}
.graph_container > .MuiPaper-root > div:last-child > canvas {
  min-height: 350px;
  margin-bottom: 10px;
}
.graph_container_circle > .MuiPaper-root {
  margin-bottom: 10px;
}

.graph_container_circle canvas {
  width: 100%;
  margin: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

/* MOBILE */
@media (max-width: 765px) {
  .dashboard_container {
    margin-left: 240px;
    grid-template-columns: repeat(auto-fit, minmax(359px, 1fr));
    grid-gap: 10;
  }
}
@media (max-width: 600px) {
  .dashboard_container {
    margin-left: 0;
    grid-template-columns: repeat(auto-fit, minmax(359px, 1fr));
    grid-gap: 10;
  }
}
